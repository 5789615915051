:root:root {
  // --van-blue: #83b1ee;
  // --van-blue: #1652f0;
  --van-blue: #007AFF;
  --van-background-2: #1a212a;
  --van-background-3: #262e3b;
  --van-text-color-2: #768397;
  --van-white: #f5f5f5;
  --van-background: var(--van-background-2);
  --van-search-background: transparent;
  --van-radius-sm: 6px;
  --van-tabbar-height: 60px;
  --van-popup-background: var(--van-background-3);
  --van-tabs-nav-background: var(--van-background-3);
  // --van-font-size-md: 14px;
  --el-fill-color-blank: var(--van-background-2);
  --el-bg-color-overlay: var(--van-background-3);
  --el-text-color-placeholder: var(--van-field-placeholder-text-color);
  --el-fill-color-light: var(--van-background-2);
  --el-text-color-primary: var(--van-text-color-2);
  --el-color-primary: var(--van-blue);
  --el-input-text-color: var(--van-white);
  --el-border-color: var(--van-border-color);
  --el-border-radius-base: var(--van-radius-sm);
}

.text-gray {
  color: var(--van-text-color-2);
}