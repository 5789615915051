.van-popover__wrapper {
  display: block;
}
.custom-list {
  .van-cell {
    font-size: var(--van-font-size-sm);
    padding: var(--van-cell-vertical-padding) var(--van-cell-horizontal-padding);
    & ~ .van-cell {
      padding: 0 var(--van-cell-horizontal-padding) var(--van-cell-vertical-padding) var(--van-cell-horizontal-padding);
    }
  }
}
.van-contact-list__item-tag {
  font-size: 12px;
}
.van-contact-list__bottom {
  background: var(--van-background-3);
}
.van-tabbar {
  border-radius: 18px 18px 0 0;
  overflow: hidden;
}
.el-select__popper.el-popper {
  border-radius: 6px;
  border: none;
}
.el-select__wrapper {
  box-shadow: none !important;
  font-size: 12px !important;
  min-height: 32.53px;
}
.el-select-dropdown__item {
  color: var(--van-white);
  position: relative;
  font-size: 12px;
  &:hover {
    background: none;
  }
  // &::before {
  //   border-bottom: 1px solid var(--van-background-2);
  //   content: "";
  //   height: 1px;
  //   width: calc(100% - 8.53333vw);
  //   position: absolute;
  //   top: 0;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   display: none;
  // }
  // & ~ .el-select-dropdown__item::before {
  //   display: block;
  // }
}

.el-notification {
  border: unset;
  .el-notification__content {
    color: var(--van-white);
  }
}