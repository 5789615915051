@import './theme/index.less';
@import './cover.less';

* {
  box-sizing: border-box;
}

body {
  background-color: var(--van-background-3);
}
p {
  padding: 0;
  margin: 0;
}
