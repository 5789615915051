
.g-select {
  background: var(--van-search-content-background);
  border-radius: var(--van-radius-sm);
  color: var(--van-field-input-text-color);
  input {
    height: var(--van-search-input-height);
    font-size: 14px;
    &::placeholder {
      color: var(--van-field-placeholder-text-color)
    }
  }
  .g-select-icon {
    height: var(--van-search-input-height);
    width: calc(var(--van-padding-md) * 2);
  }
  .g-select-arrow {
    transition: .3s ease;
  }
  &:focus-within .g-select-arrow {
    transform: rotate(90deg);
  }
}
